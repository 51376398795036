import templateUrl from './advancedEventForm.html';
import './advancedEventForm.less';

export default angular.module('eventix.dashboard.wizard.common.advancedEventForm', [])
    .component('advancedEventForm', {
        controller: AdvancedEventFormController,
        templateUrl: templateUrl,
        bindings: {
            save: '&?',
            event: '<',
            cancel: '&?',
            // accessProduct: '=?',
            translations: '<?',
            formOptions: '<'
        }
    }).name;

function AdvancedEventFormController($scope, $q, ErrorRejector, TemplateModal, $translate, Role) {
    const $ctrl = this;

    const subcategories = {
        'festival': ['00s', '90s', '80s', '70s', '60s', '50s', 'house', 'future-house', 'techno', 'urban', 'hip-hop', 'apres-ski', 'blues', 'blues-rock', 'metal', 'classical', 'dance', 'drum-bass', 'dubstep', 'hardstyle', 'hardcore', 'pop', 'indie', 'trance', 'disco', 'edm', 'jazz', 'dutch', 'dance-hall', 'funk', 'groove', 'hard-techno', 'punk', 'psy-trance', 'rave', 'reggae', 'ska', 'latin', 'melodic-techno', 'live-electronic', 'afro', 'bass', 'folk', 'hits', 'rock', 'acoustic', 'soul', 'tech-house', 'tribute'],
        'business': ['convention', 'conference', 'training', 'workshop', 'master-class', 'network', 'course', 'exposition', 'gala'],
        'fashion': ['gala', 'conference', 'training', 'workshop', 'master-class', 'network', 'exposition', 'sample-sale', 'catwalk-show'],
        'sports': ['gala', 'training', 'running', 'obstacle run', 'football', 'golf', 'rugby', 'baseball', 'basketball', 'table tennis', 'volleyball', 'field hockey', 'cricket', 'rally', 'horse back riding'],
        'theatre': ['musical', 'comedy', 'dance', 'ballet', 'opera', 'play', 'kids', 'dancing'],
        'family': [],
        'adult': [],
        'circus': [],
        'art-film': ['screening', 'convention', 'conference', 'training', 'workshop', 'master-class', 'network', 'course', 'exposition', 'gala', 'reading', 'film', 'junior', 'spoken-word', 'cultural' ],
        'food-drinks': [],
        'concert': ['00s', '90s', '80s', '70s', '60s', '50s', 'house', 'future-house', 'techno', 'urban', 'hip-hop', 'apres-ski', 'blues', 'blues-rock', 'metal', 'classical', 'dance', 'drum-bass', 'dubstep', 'hardstyle', 'hardcore', 'pop', 'indie', 'trance', 'disco', 'edm', 'jazz', 'dutch', 'dance-hall', 'funk', 'groove', 'hard-techno', 'punk', 'psy-trance', 'rave', 'reggae', 'ska', 'latin', 'melodic-techno', 'live-electronic', 'afro', 'bass', 'folk', 'hits', 'rock', 'acoustic', 'soul', 'tech-house', 'tribute'],
        'club': ['00s', '90s', '80s', '70s', '60s', '50s', 'house', 'future-house', 'techno', 'urban', 'hip-hop', 'apres-ski', 'blues', 'blues-rock', 'metal', 'classical', 'dance', 'drum-bass', 'dubstep', 'hardstyle', 'hardcore', 'pop', 'indie', 'trance', 'disco', 'edm', 'jazz', 'dutch', 'dance-hall', 'funk', 'groove', 'hard-techno', 'punk', 'psy-trance', 'rave', 'reggae', 'ska', 'latin', 'melodic-techno', 'live-electronic', 'afro', 'bass', 'folk', 'hits', 'rock', 'acoustic', 'soul', 'tech-house', 'tribute'],
        'charity': ['convention', 'conference', 'master-class', 'network', 'exposition', 'gala'],
        'hobby': [],
        'museum': [],
        'gaming': ['e-sports', 'conference', 'network'],
        'escape-room': [],
    };

    $ctrl.submit = submit;
    $ctrl.unsubmit = unsubmit;
    $ctrl.openTemplateModal = openTemplateModal;
    $ctrl.removeSubcategory = removeSubcategory;
    $ctrl.formatVisitorContactUrl = formatVisitorContactUrl;

    $ctrl.isAdmin = Role.isAuthorizedAs('Admin');
    $ctrl.isAdminOrWLAdmin = Role.isAuthorizedAs('Admin') || Role.isAuthorizedAs('Whitelabel Admin');
    $ctrl.gui_modes = ['simple', 'advanced'];
    $ctrl.event_types = ['once', 'repeating'];
    $ctrl.categories = translateCategories(_.keys(subcategories));
    $ctrl.subcategories = translateCategories([], 'models.event.subcategories');
    $ctrl.guids = {
        event: {
            guid: $ctrl.event ? $ctrl.event.guid : '',
            icon: 'fa-calendar',
            name: 'models.models.event',
        },
        location: {
            guid: $ctrl.location ? $ctrl.location.guid : '',
            icon: 'fa-map-marker',
            name: 'models.models.location',
        },
    };

    $ctrl.$postLink = function() {
        if (!$ctrl.translations) {
            $ctrl.translations = {};
        }

        _.defaults($ctrl.translations, {
            cancel: 'common.action.back',
            save: 'common.action.save',
        });

        getLocation($ctrl.event).catch(ErrorRejector.handle);

        $scope.$watch('$ctrl.location', location => {
            if ($ctrl.event) {
                $ctrl.event.$errors.clear();
            }

            if (location && location.guid) {
                _.set($ctrl.event, 'location_id', location.guid);
                _.set($ctrl.event, 'location', location);
            }
        });

        $scope.$watch('$ctrl.event.category', (newCategory, oldCategory) => {
            $ctrl.subcategories = translateCategories(_.get(subcategories, newCategory, []), 'models.event.subcategories');

            if ((newCategory !== oldCategory && !_.isNil(oldCategory)) || !$ctrl.event.subcategories || $ctrl.event.subcategories.length < 1) {
                $ctrl.event.subcategories = ['other'];
            }
        });

        $scope.$watch('$ctrl.event.subcategories', subcategories => {
            if (subcategories.length < 1) {
                $ctrl.event.subcategories = ['other'];
            }
        });

        if (!$ctrl.event.category) {
            $ctrl.event.category = 'other';
        }

        $scope.$watch('$ctrl.event.guid', (guid) => {
            $ctrl.guids.event.guid = guid || '';
        });
        $ctrl.guids.event.guid = $ctrl.event ? $ctrl.event.guid : '';
        $scope.$watch('$ctrl.location.guid', (guid) => {
            $ctrl.guids.location.guid = guid || '';
        });
        $ctrl.guids.location.guid = $ctrl.location ? $ctrl.location.guid : '';
    };

    function submit() {
        $ctrl.save({
            event: $ctrl.event,
            location: $ctrl.location,
        });
    }

    function unsubmit() {
        $ctrl.cancel();
    }

    /**
     * Get or create a location for date
     *
     * @param {Event} event The event who's location to get
     *
     * @return {Promise<EventLocation>} An EventLocation
     */
    function getLocation(event) {
        if (!event.guid && !event.location_id) {
            return $q.resolve();
        }

        return event.$getLocation()
            .then(location => $ctrl.location = location);
    }

    function openTemplateModal() {
        TemplateModal.open('event', $ctrl.event);
    }

    /**
     * Remove a subcategory from the event
     *
     * @param {string} subcategory The subcategory to remove from the event
     */
    function removeSubcategory(subcategory) {
        _.pull($ctrl.event.subcategories, subcategory);
    }

    /**
     * Translates the given (sub)categories and adds the other option
     *
     * @param {Array<string>} categories The categories to translate (and add the 'other' option to)
     * @param {string} prefix The translation key prefix
     *
     * @return {Object<string, {category: string, value: string}>} The map of category keys to labels
     */
    function translateCategories(categories, prefix = 'models.event.categories') {
        const translationKeys = _.keyBy(_.concat(categories, ['other']), category => `${prefix}.${category}`);

        return _.keyBy(_.map($translate.instant(_.keys(translationKeys)), (value, key) => {
            const category = _.get(translationKeys, key, key);

            return {
                category: category,
                label: value === key ? category : value
            };
        }), 'category');
    }

    function formatVisitorContactUrl() {
        if (_.isString($ctrl.event.visitor_contact_url) && $ctrl.event.visitor_contact_url.length > 0 && $ctrl.event.visitor_contact_url.indexOf('http') !== 0) {
            $ctrl.event.visitor_contact_url = 'https://' + $ctrl.event.visitor_contact_url;
        }
    }
}
