import templateUrl from './forcedProducts.html';
import rudderstack from "../../../../../rudderstack/rudderstack";

export default angular.module('eventix.dashboard.wizard.common.shop.forcedProducts', [])
    .component('forcedProducts', {
        controller: forcedProductsController,
        templateUrl: templateUrl,
        bindings: {
            prefix: '<',
        }
    }).name;

function forcedProductsController(UIMessages, $scope, LaravelValidationRules, $timeout, $http, $translate) {
    const $ctrl = this;

    $ctrl.forcedProducts = [];
    $ctrl.type;
    $ctrl.showMoreRPInfoUrl = (DASHBOARD_REFUND_PROTECT_URL).toString() !== '';
    $ctrl.moreInfoRPUrl = DASHBOARD_REFUND_PROTECT_URL;

    $ctrl.$onInit = function () {
        if(($ctrl.prefix).includes('company')) {
            $ctrl.type = 'company';
        }
        if(($ctrl.prefix).includes('shop')){
            $ctrl.type = 'shop';
        }

        fetchFP();


        $scope.$watch('$ctrl.forcedProducts', function(newCollection,oldCollection){
            _.forEach($ctrl.forcedProducts, fp => {
                // Dont ask
                let newValue = _.get(_.find(newCollection, function(pp) { return pp.product_id == fp.product_id; }), 'preferences.'+$ctrl.type);
                let oldValue = _.get(_.find(oldCollection, function(pp) { return pp.product_id == fp.product_id; }), 'preferences.'+$ctrl.type);

                // skip init watcher
                if(oldValue === undefined){
                    return;
                }
                if(newValue !== oldValue){
                    trackInteraction(fp, newValue, oldValue);
                    // update or reset
                    if(_.isNull(newValue)){
                        reset(fp);
                    }
                    else{
                        saver(fp);
                    }
                }
            })
        }, true);

    };

    function fetchFP(){
        $http.get(`/forced-products/${$ctrl.prefix}`,).then(result => {
            let fps = result.data.data;

            if($ctrl.type == 'shop'){
                $ctrl.forcedProducts = _.filter(fps, fp => fp.updatable_by == 'shop');
            }else {
                $ctrl.forcedProducts = fps;
            }

            $ctrl.forcedProducts.forEach(p => {
                p.translatableName = _.snakeCase(p.product.name);
                p.showSettings = false;

                // Set some helpers
                if(($ctrl.prefix).includes('company')){
                    p.currentInherittedBoolean = p.preferences.self;
                    p.currentInheritLevel = DASHBOARD_BRAND_NAME;
                }
                if(($ctrl.prefix).includes('shop')){
                    p.currentInherittedBoolean = !_.isNull(p.preferences.company) ? p.preferences.company : p.preferences.self;
                    p.currentInheritLevel = !_.isNull(p.preferences.company) ? 'company' : DASHBOARD_BRAND_NAME;
                }
                p.currentInherittedValue = p.currentInherittedBoolean ? $translate.instant('models.forced_products.options.enabled') : $translate.instant('models.forced_products.options.disabled');

                // Set translated values if exists
                p.translatedValues = {
                    default: ($translate.instant('models.forced_products.'+p.translatableName+'.values.default') != 'models.forced_products.'+p.translatableName+'.values.default' ? 'models.forced_products.'+p.translatableName+'.values.default' : 'models.forced_products.options.default'),
                    enabled: ($translate.instant('models.forced_products.'+p.translatableName+'.values.enabled') != 'models.forced_products.'+p.translatableName+'.values.enabled' ? 'models.forced_products.'+p.translatableName+'.values.enabled' : 'models.forced_products.options.enabled'),
                    disabled: ($translate.instant('models.forced_products.'+p.translatableName+'.values.disabled') != 'models.forced_products.'+p.translatableName+'.values.disabled' ? 'models.forced_products.'+p.translatableName+'.values.disabled' : 'models.forced_products.options.disabled'),
                }
            })
        });
    }

    function saver(fp){
        $http.put(`/forced-products/${fp.product.guid}/${$ctrl.prefix}`, {enabled: fp.preferences[$ctrl.type]});
    }
    function reset(fp){
        $http.delete(`/forced-products/${fp.product.guid}/${$ctrl.prefix}`);
    }

    $ctrl.trackInteraction = trackInteraction;
    function trackInteraction(fp, newValue, oldValue){
        rudderstack.track('forced_product_interaction', {
            product_id: fp.product.guid,
            product_name: fp.product.name,
            old: oldValue,
            new: newValue,
        });
    }
}
